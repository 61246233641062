import React from "react"
import { Item, Label, Icon, Button } from "semantic-ui-react"

const paragraph = `Duis aute irure dolor in reprehenderit in voluptate velit esse
cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
cupidatat non proident, sunt in culpa qui officia deserunt
mollit anim id est laborum.......`

const SingleUserPosts = () => {
  return (
    <div>
      <h1
        style={{
          margin: "50px auto",
          display: "table",
          borderBottom: "2px solid #ffa41b",
        }}
      >
        Posted by Nivedita
      </h1>
      <Item.Group divided>
        <Item>
          <Item.Image
            className="user-profile-card-img"
            src="https://source.unsplash.com/1280x720/?Salad"
          />

          <Item.Content>
            <Item.Header as="a">Vegan salad </Item.Header>
            <Item.Meta>
              <span className="cinema">lorem ipsum doler sit</span>
            </Item.Meta>
            <Item.Description>{paragraph}</Item.Description>
            <Item.Extra>
              <Button basic size="mini" color="orange" floated="right">
                READ MORE
                <Icon name="right chevron" />
              </Button>
              <Label>vegan</Label>
              <Label content="salad" />
            </Item.Extra>
          </Item.Content>
        </Item>

        <Item>
          <Item.Image
            className="user-profile-card-img"
            src="https://source.unsplash.com/1280x720/?Salad,1"
          />

          <Item.Content>
            <Item.Header as="a">Banana leaf chicken curry</Item.Header>
            <Item.Meta>
              <span className="cinema">lorem doler sit amet</span>
            </Item.Meta>
            <Item.Description>{paragraph}</Item.Description>
            <Item.Extra>
              <Button basic size="mini" color="orange" floated="right">
                READ MORE
                <Icon name="right chevron" />
              </Button>
              <Label>curry</Label>
            </Item.Extra>
          </Item.Content>
        </Item>
        <Item>
          <Item.Image
            className="user-profile-card-img"
            src="https://source.unsplash.com/1280x720/?Salad"
          />

          <Item.Content>
            <Item.Header as="a">Vegan salad </Item.Header>
            <Item.Meta>
              <span className="cinema">lorem ipsum doler sit</span>
            </Item.Meta>
            <Item.Description>{paragraph}</Item.Description>
            <Item.Extra>
              <Button basic size="mini" color="orange" floated="right">
                READ MORE
                <Icon name="right chevron" />
              </Button>
              <Label>vegan</Label>
              <Label content="salad" />
            </Item.Extra>
          </Item.Content>
        </Item>

        <Item>
          <Item.Image
            className="user-profile-card-img"
            src="https://source.unsplash.com/1280x720/?Salad,1"
          />

          <Item.Content>
            <Item.Header as="a">Banana leaf chicken curry</Item.Header>
            <Item.Meta>
              <span className="cinema">lorem doler sit amet</span>
            </Item.Meta>
            <Item.Description>{paragraph}</Item.Description>
            <Item.Extra>
              <Button basic size="mini" color="orange" floated="right">
                READ MORE
                <Icon name="right chevron" />
              </Button>
              <Label>curry</Label>
            </Item.Extra>
          </Item.Content>
        </Item>
        <Item>
          <Item.Image
            className="user-profile-card-img"
            src="https://source.unsplash.com/1280x720/?Salad"
          />

          <Item.Content>
            <Item.Header as="a">Vegan salad </Item.Header>
            <Item.Meta>
              <span className="cinema">lorem ipsum doler sit</span>
            </Item.Meta>
            <Item.Description>{paragraph}</Item.Description>
            <Item.Extra>
              <Button basic size="mini" color="orange" floated="right">
                READ MORE
                <Icon name="right chevron" />
              </Button>
              <Label>vegan</Label>
              <Label content="salad" />
            </Item.Extra>
          </Item.Content>
        </Item>

        <Item>
          <Item.Image
            className="user-profile-card-img"
            src="https://source.unsplash.com/1280x720/?Salad,1"
          />

          <Item.Content>
            <Item.Header as="a">Banana leaf chicken curry</Item.Header>
            <Item.Meta>
              <span className="cinema">lorem doler sit amet</span>
            </Item.Meta>
            <Item.Description>{paragraph}</Item.Description>
            <Item.Extra>
              <Button basic size="mini" color="orange" floated="right">
                READ MORE
                <Icon name="right chevron" />
              </Button>
              <Label>curry</Label>
            </Item.Extra>
          </Item.Content>
        </Item>
      </Item.Group>
    </div>
  )
}

export default SingleUserPosts
