import React from "react"
import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import { Card, Grid, Segment, Divider } from "semantic-ui-react"
import SingleUserPosts from "../components/UserProfile/user-posts"

const UserProfilePage = () => {
  return (
    <Layout>
      <SEO title="User Profile | My Food Book " />
      <div style={{ margin: "100px 0" }}>
        <Card style={{ width: "90vw", maxWidth: "700px" }} centered>
          <Card.Content>
            <Grid columns={2} stackable padded="horizontally">
              <Grid.Row centered>
                <Grid.Column
                  width="6"
                  verticalAlign="middle"
                  textAlign="center"
                >
                  <img
                    src="https://scontent-bom1-2.xx.fbcdn.net/v/t1.0-9/56481786_2678553215507313_7190368848049078272_n.jpg?_nc_cat=111&_nc_sid=174925&_nc_ohc=tB4tQ_THuHgAX_iBAHN&_nc_ht=scontent-bom1-2.xx&oh=412adb2db5a63bb59856ec9bf2fa3479&oe=5EBDBF30"
                    style={{
                      width: "200px",
                      height: "200px",
                      objectFit: "cover",
                      borderRadius: "50%",
                      border: "1.5px solid #ffa41b",
                      padding: "2px",
                    }}
                    alt="user-icon"
                  />
                </Grid.Column>
                <Grid.Column width="10" textAlign="center">
                  <Segment>
                    <h1>Nibedita Satapathy</h1>

                    <p
                      style={{
                        color: "grey",
                        fontWeight: "700",
                        letterSpacing: "2px",
                      }}
                    >
                      House Wife
                    </p>

                    <p
                      style={{
                        color: "grey",
                      }}
                    >
                      Member since <strong> April, 2020 </strong>
                    </p>
                    <p>
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </p>
                  </Segment>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Divider />
            <SingleUserPosts />
          </Card.Content>
        </Card>
      </div>
    </Layout>
  )
}

export default UserProfilePage
